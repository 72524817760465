@use 'colors' as colors;

.App {
  margin: 0;

  header {
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    box-sizing: border-box;
    z-index: 10;
  }


  section {
    h2 {
      background: -webkit-linear-gradient(#FFE0A5, #CA981F);
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 1px 1px 0 1 black;
      text-align: center;
      line-height: 1.5em;
      font-size: 32px;
      margin-top: 64px;
      text-transform: uppercase;
    }
  }

  .desktop-background {
    background:
      url('../assets/banner/newYear/web_mt_footer_wave1.webp'),
      url('../assets/banner/newYear/web_mt_footer_wave2.webp'),
      url('../assets/banner/newYear/web_mt_footer_wave3.webp'),
      url('../assets/banner/newYear/web_mt_footer_wave4.webp'),
      url('../assets/banner/newYear/web_mt_misc_cloud_a.svg'),
      url('../assets/banner/newYear/web_mt_misc_cloud_b.svg'),
      url('../assets/banner/newYear/web_mt_misc_cloud_c.svg'),
      url('../assets/banner/newYear/web_mt_bg_pattern.svg'),
      url('../assets/banner/newYear/web_mt_bg_pattern.svg'),
      url('../assets/banner/newYear/web_mt_bg.jpg');
    background-repeat:
      no-repeat,
      no-repeat,
      no-repeat,
      no-repeat,
      no-repeat,
      no-repeat,
      no-repeat,
      repeat-y,
      repeat-y,
      repeat-y;
    background-size:
      100%,
      100%,
      100%,
      100%,
      10%,
      12%,
      34%,
      18px,
      18px,
      contain;
    background-position:
      bottom,
      bottom,
      bottom,
      bottom,
      top 25% left 25%,
      top 30% right 30%,
      top 40% right 20%,
      left,
      right,
      center;
    background-position-x:
      0%,
      0%,
      0%,
      0%,
      25%,
      83%,
      78%,
      12%,
      88%,
      0%;
  }

  .mobile-background {
    background: 
      url('../assets/banner/newYear/web_mt_footer_wave1.webp'),
      url('../assets/banner/newYear/web_mt_footer_wave2.webp'),
      url('../assets/banner/newYear/web_mt_footer_wave3.webp'),
      url('../assets/banner/newYear/web_mt_footer_wave4.webp'),
      url('../assets/banner/newYear/web_mt_misc_cloud_a.svg'),
      url('../assets/banner/newYear/web_mt_misc_cloud_b.svg'),
      url('../assets/banner/newYear/web_mt_misc_cloud_c.svg'),
      url('../assets/banner/newYear/mob_mt_rb_wave5.webp'),
      url('../assets/banner/newYear/mob_mt_rb_golden.webp');
    background-repeat:
      no-repeat,
      no-repeat,
      no-repeat,
      no-repeat,
      no-repeat,
      no-repeat,
      no-repeat,
      no-repeat,
      repeat-y;
    background-size:
      100%,
      100%,
      100%,
      100%,
      30%,
      30%,
      90%,
      100%,
      100%;
    background-position:
      bottom,
      bottom,
      bottom,
      bottom,
      top 20% left 10%,
      top 30% right 15%,
      top 40% center,
      bottom,
      center;
  }

}

@media only screen and (max-width: 768px) {
  .App {
    section {
      h2 {
        font-size: 2em;
      }
    }
  }
  
}
