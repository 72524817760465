@use 'variables' as variables;
@use 'colors' as colors;

.loading-banner {
  height: 100vh;
  min-height: 700px;
  min-width: 350px;
  max-height: 1080px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.banner {
  height: 100vh;
  min-height: 830px;
  min-width: 350px;
  max-height: 890px;
  .parallax-banner {
    height: 100%;    
    min-height: 700px;
    min-width: 350px;
    max-height: 1080px;
  }

  .cover-1 {
    width: 100%;
    height: 100%;
    background-size: 100% 100% !important;
    background-position: top center !important;
    animation: fadeInAnimation ease 1s;
  }

  .cover-1-mobile {
    width: 100%;
    height: 100%;
    background-size: 120% !important;
    background-repeat: no-repeat;
    background-position: top center !important;
    animation: fadeInAnimation ease 1s;
  }

  .lantern {
    width: 100%;
    background-size: contain !important;
    background-repeat: no-repeat;
    margin-top: -13%;
  }

  .circle {
    width: 100%;
    background-size: 100% !important;
    background-repeat: no-repeat;
    background-position-y: 93px !important;
  }

  .fans {
    width: 100%;
    background-size: 100% !important;
    background-repeat: no-repeat;
    background-position-y: 87% !important;
  }

  .wave1, .wave2, .wave3, .wave4, .wave5 {
    width: 100%;
    background-size: 100% !important;
    background-repeat: no-repeat;
    background-position-y: bottom !important;
  }

  .cloud {
    background-repeat: no-repeat;
    background-size: min(200px, 12%) !important;
  }

  .blue-cloud-left-1 {
    background-position:  left 18% bottom 30% !important;
  }

  .blue-cloud-left-2 {
    background-position:  left 7% bottom 15% !important;
  }

  .red-cloud-right-2 {
    background-position:  right 7% bottom 15% !important;
  }

  .red-cloud-left-1 {
    background-position:  left 2% bottom 40% !important;
  }

  .red-cloud-right-1 {
    background-position:  right 3% bottom 42% !important;
  }

  .blue-cloud-right-1 {
    background-position:  right 12% bottom 30% !important;
  }

  .stairs {
    width: 100%;
    margin: auto;
    background-size: 100% !important;
    background-repeat: no-repeat;
    background-position-y: 100% !important;
  }

  .confetti {
    background-size: 100% !important;
    background-repeat: no-repeat;
  }

  .logo-container {
    position: relative;
  }

  .mt-logo {
    width: 400px;
    height: 320px;
    background-size: contain !important;
    background-repeat: no-repeat;
    margin: 80px auto;
    position: relative;
    animation: floating-logo 4s ease-in-out infinite;
  }

  .dragon-eye {
    position: absolute;
    top: 97px;
    left: 196px;
    animation: glowing-eye 4s ease-in-out infinite;
  }

  .mt-text-slogan {
    max-width: 1536px;
    max-height: 820px;
    min-height: 700px;
    width: 100vw;
    height: 90vh;
    background-size: contain !important;
    background-repeat: no-repeat;
    margin: 64px auto;
    margin-bottom: 0px;

    img {
      display: block;
      max-width: 1075px;
      width: 90%;
      height: auto;
      margin: 64px auto -80px auto;
    }
  }



  .title-logo {
    width: 100%;
    margin: auto;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    font-size: 24px;
    white-space: break-spaces;
    color: colors.$title-logo-date-text-color;
    -webkit-text-stroke: 1px colors.$title-logo-date-stroke;

    p {
      font-size: 24px;
    }
  }

  .ranking-board-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: min(31%, 286px) auto 0px auto;
    width: 92vw;
    max-width: 1200px;

    .congrats-text {
      background: -webkit-linear-gradient(#ffffff, colors.$ranking-board-congrats-text-color-2);
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .default-date {
      color: colors.$navbar-text-color;
      font-size: 18px;
      margin: 8px 0px;
      display: flex;
      flex-direction: row;
      gap: 16px;
      justify-content: space-between;
      align-items: center;

      button {
        background: transparent;
        border: none;
        color: white;
      }

      button:disabled {
        color: grey
      }
    }

    .date-range-picker-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      .date-picker {
        width: 200px;
        background: transparent;
        color: colors.$navbar-text-color;
        border: none;
        font-size: 18px;
        cursor: pointer;
        text-align: center;
      }
      button {
        background: transparent;
        color: white;
        border: none;
        cursor: pointer;
      }

    }
    .mobile-first-row, .mobile-second-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .rankings-container {
      white-space: pre-line;
      margin-top: 16px;

      .first-row, .second-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        gap: 16px;
        margin-top: 20px;
        background: url('../assets/banner/newYear/web_mt_misc_rankingboard_line_revised.webp');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 0px -21px;
      }

      .date-line {
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translate(-50%, -50%);
        color: colors.$title-logo-date-text-color;
      }      
    }
  }

  .mobile-first-row, .mobile-second-row {
    grid-template-columns: 1fr 1fr;
    white-space: pre-line;
  }

  .gold-badge {
    width: 180px;
    background: url('../assets/banner/newYear/web_mt_misc_rankingboard_badge.webp');
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 16px;
    background-color: 'black';
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: normal;

    aside {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: fit-content;
      margin-top: -10px;

      p {
        font-size: 15px;
        text-align: center;
        color: colors.$introduction-card-text-color;
      }
    }
    .sub-text {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      flex-wrap: nowrap;
      width: 100px;
      height: 25px;
      margin-left: -9px;

      p {
        font-size: 18px;
        color: colors.$ranking-board-sub-text-color;
      }
    }

    .en-p {
      margin-top: -28px;
      text-align: justify;
    }

    .gold-badge-title {
      font-size: 18px;
      margin-top: -20px;
      margin-bottom: -27px;
      color: colors.$ranking-board-top-text-color;
      text-align: center;
    }

    .top-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      margin-top: 17px;
    }
  }

  .mobile-day-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    
    button {
      color: colors.$ranking-board-enabled-text-color;
      background: transparent;
      border: none;
      font-size: 21px;
      cursor: pointer;
    }


  }

  .left-underline {
    background: url('../assets/banner/newYear/mob_mt_misc_rb_line_left_gap.webp');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: bottom;
    background-position-x: 2px;

    button:nth-child(2) {
      font-size: 16px;
    }
  }

  .right-underline {
    background: url('../assets/banner/newYear/mob_mt_misc_rb_line_right_gap.webp');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: bottom;
    background-position-x: -2px;

    button:first-child {
      font-size: 16px;
    }
  }

}



@media only screen and (max-width: 872px) {
  .banner {
    height: 100vh;
    min-height: 556px;
    min-width: 350px;
    max-height: 700px;

    .mt-logo {
      width: 250px;
      height: 250px;
      min-width: unset;
      margin: clamp(52px, 11vh, 138px) auto;
    }

    .dragon-eye {
      position: absolute;
      top: 64px;
      left: 126px;
    }

    .circle {
      background-size: 216% !important;
      background-position-y: 153px !important;
    }

    .cloud {
      background-size: 20% !important;
    }

    .lantern {
      background-position-y: 133px !important;
    }

    .stairs {
      background-size: 100% 21vw !important;
    }

    .blue-cloud-left-1 {
      background-position:  left 18% bottom 25% !important;
    }

    .blue-cloud-right-1 {
      background-position: right -9% bottom 37% !important;
    }

    .red-cloud-right-2 {
      background-position:  right -1% bottom 18% !important;
    }

    .red-cloud-left-1 {
      background-position:  left -8% bottom 30% !important;
    }

    .red-cloud-right-1 {
      background-position:  right 10% bottom 28% !important;
    }

    .blue-cloud-left-2 {
      background-position:  left -4% bottom 21% !important;
    }

    .mt-text-slogan {
      width: 100%;
      height: auto;
      max-width: 500px;
      aspect-ratio: 2/3;
      min-height: 560px;
      margin: clamp(30px, 10vh, 200px) auto 0px auto;

      img {
        width: 100%;
        max-width: 400px;
        height: auto;
        margin: 162px auto 0px auto;
      }
    }

    .parallax-banner {
      height: 100%;
      min-height: 600px;
      min-width: 350px;
      max-height: 1080px;
    }

    .title-logo {
      width: 200px;
      text-align: center;
      margin: auto;

      p {
        font-size: 18px;
        line-height: 1em;
        margin-bottom: 0px;
      }
     
    }

    .ranking-board-container {

      margin: 330px auto 0px auto;

      .top-master-title {
        img {
          margin-top: -38px;
        }
      }
      .congrats-text {
        color: colors.$ranking-board-sub-text-color;
        font-size: 18px;
      }

      .congrats-text-cn {
        color: colors.$ranking-board-sub-text-color;
        font-size: 20px;
      }
    }
  }

  
  
  

  
}


@media only screen and (min-width: 873px) and (max-width: 1200px) {
  .banner {

    .circle {
      margin-top: 0px;
      background-size: 150% !important;
    }
    .title-logo {

      bottom: min(48px, 7%);
    }
  }
}

@keyframes fadeInAnimation {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes floating-logo {
  0% { top: 0px }
  50% { top: 10px }
  100% { top: 0px }
}

@keyframes glowing-eye {
  0% { opacity: 0.8; }
  50% { opacity: 0.1 }
  100% { opacity: 0.8; }
}
