@use 'colors' as colors;
@use 'variables' as variables;

.preheader {
  height: 80px;
  background: linear-gradient(to bottom, rgba(0,0,0) 29%, rgba(0,0,0,0));
  color: colors.$navbar-text-color;
  font-weight: normal;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: variables.$body-max-width;
  margin: auto;
  padding: 0 24px;

  .audio-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: -16px;
  }

  .audio-btn {
    border-radius: 8px;
    background-color: inherit;
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .audio-background {
    margin-left: 24px;
    height: 30px;
  }

  .ag-logo-container {
    display: flex;
    flex-direction: row;
    margin-left: 24px;
  }

  .right-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;

    .navigation {
      margin-right: 16px;
    }

    ul {
      display: flex;
      flex-direction: row;
      gap: 36px;
      align-items: center;
      list-style: none;

      a, a:visited {
        color: colors.$navbar-text-color;
        text-decoration: none;
        font-size: 20px;
      }

      a:hover, .active-link {
        background: -webkit-linear-gradient(#FFCD19, #FFE8BC, #FFC53B, #E4A100);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        //text-shadow: 1px 1px 0 rgba(0,0,0,0.50);
        //paint-order: stroke fill;
        //-webkit-text-stroke: 1px #D29E00;
        // text-shadow: 0px 0px 1px #D29E00;
        
      }


    }

  }

  .menu-icon-container {
    margin-right: 15px;
  }
  
  
}

.colored-text {
  background: -webkit-linear-gradient(#FFCD19, #FFE8BC, #FFC53B, #E4A100);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  
}

a, a:visited {
  color: colors.$navbar-text-color;
  text-decoration: none;
}

.drop-down {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 10px;
      align-items: center;
      margin-right: 18px;
      align-self: flex-end;
      p {
        color: colors.$navbar-text-color;
        font-size: 16px;
      }

      &:hover p {
        color: colors.$navbar-anchor-highlight;
      }
    }

@media only screen and (max-width: 872px) {
  .preheader {
    padding: 0 16px;
    .menu-icon-container {
      margin: 0px
    }

    .audio-container {
      margin-right: unset;
    }
  }
}

@media only screen and (min-width: 873px) and (max-width: 1000px) {
  .preheader {
    .right-container {
      ul {
        a, a:visited {
          font-size: 18px;
        }
      }
    }
  }
}